import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import { PersonBySlugQuery } from '../../graphql-types'
import { Content } from '../components/styled'
import styled from 'styled-components'
import { DEFAULT_SIZE, next, prev } from '../Utils'
import MarkdownRenderer from '../components/MarkdownRenderer'
import copy from 'copy-to-clipboard'

const PersonPage = ({
  data,
  location,
}: {
  data: PersonBySlugQuery
  location: Location
}) => {
  const [emailText, setEmailText] = useState(data.contentfulPerson.email)
  const siteTitle = get(data, 'site.siteMetadata.title')
  const post = get(data, 'contentfulBlogPost')

  return (
    <Layout location={location}>
      <Helmet title={data.contentfulPerson.name} />
      <Content>
        <Person>
          <div className="text">
            <Img
              style={{ width: '66.666%', marginTop: '0.6rem' }}
              alt={''}
              fluid={data.contentfulPerson.image.fluid}
            />
            <br />
            <b>
              <p style={{ padding: '0.2rem 0 0 0', margin: 0 }}>
                {data.contentfulPerson.name}
              </p>
            </b>
            <p style={{ padding: 0, margin: 0 }}>
              {data.contentfulPerson.title}
            </p>
            <p style={{ padding: 0, margin: '0 0 1rem 0' }}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  copy(data.contentfulPerson.email)
                  setEmailText(emailText + ' (Copied!)')
                }}
              >
                E: {emailText}
              </div>
            </p>
          </div>
        </Person>
        <div>
          <MarkdownRenderer
            html={data.contentfulPerson.bio.childMarkdownRemark.html}
          />

          <br />
          <br />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <div>
              <Link
                to={
                  '/team/' +
                  prev(
                    data.allContentfulPerson.edges
                      .sort((a, b) => a.node.priority - b.node.priority)
                      .map(({ node }) => node.slug),
                    data.contentfulPerson.slug
                  )
                }
              >
                Previous
              </Link>
            </div>
            <div>
              <Link
                to={
                  '/team/' +
                  next(
                    data.allContentfulPerson.edges
                      .sort((a, b) => a.node.priority - b.node.priority)
                      .map(({ node }) => node.slug),
                    data.contentfulPerson.slug
                  )
                }
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default PersonPage

export const pageQuery = graphql`
  query PersonBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPerson {
      edges {
        node {
          priority
          slug
          name
          bio {
            bio
          }
          title
          image {
            fluid(maxWidth: 800, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    contentfulPerson(slug: { eq: $slug }) {
      slug
      name
      title
      email
      priority
      bio {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid(maxWidth: 800, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

const Person = styled.div`
  /* display: grid;
  grid: 1fr / 1fr 2fr;
  flex-direction: row;
  padding: 1rem;

  p,
  span {
    /* font-size: ${DEFAULT_SIZE}rem; */
  /* } */

  span {
    display: block;
  }

  h1 {
    margin-top: 0;
  }

  .text p {
    margin-top: 0;
  }
`
